'use client';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { ItemRarityCard } from '@/shared/ui';
import { CardItemInfo, CardItemTop } from '@/shared/ui/cards';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';

import cardItemInfoStyles from './card-item-info.module.scss';
import styles from './card-item-shop.module.scss';
import { CardItemShopEntityProps } from './card-item-shop.types';
import cardItemTopStyles from './card-item-top.module.scss';

export const CardItemShopEntityMobile = ({
  children,
  className,
  item,
  onClick = () => {},
  ...props
}: CardItemShopEntityProps) => {
  const [selectedCard, setSelectedCard] = useState(item.products[0]);
  const router = useRouter();

  const handleClick = () => {
    router.push(`/shop/${item.type}/${item?.realName}/${selectedCard.id}`);
  };

  const cardItemShopClasses = cn(styles['card-item-shop'], className);
  const colorScheme = item.game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <article className={cardItemShopClasses} {...props}>
      <CardItemTop
        onClick={handleClick}
        customStyles={cardItemTopStyles}
        // tagsInfo={tagsInfo}
        src={item.img}
        backgroundIllustration={
          <ItemRarityCard
            colorScheme={colorScheme}
            className={styles['item-rarity-card']}
            size={'100'}
            variant={item.rare}
          />
        }
      />

      <CardItemInfo
        onTitleClick={onClick}
        customStyles={cardItemInfoStyles}
        info={{
          title: item?.name,
          subtitle: formatAge(selectedCard.age ?? item.type),
          price: {
            old: selectedCard.price ?? 0,
            current: selectedCard.price ?? 0,
          },
        }}
      />
      <div
        onClick={() =>
          router.push(`/shop/${item.type}/${item?.realName}/${selectedCard.id}`)
        }
        className={styles['properties-slot']}
      >
        <SelectPetPropertiesFeature
          itemType={item.type}
          products={item.products}
          onTabChange={product => setSelectedCard(product)}
          href={`/shop/${item.type}/${item?.realName}/${selectedCard.id}`}
        />
      </div>

      <div className={styles['bottom-slot']}>
        <AddToCartFeature item={selectedCard} />
      </div>
    </article>
  );
};

//@TODO попробовать передавать CardItemTop через слот topSlot напр,
// а то 10 пропсов для сущности это овер (тоже самое в CardItem для десктопа)
