'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { ProductById } from '@/shared/types/common';
import {
  Item,
  ItemRarityCard,
  Skeleton,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { useStore } from '@/store/context';

import styles from './card-shopping-item.module.scss';
import { CardShoppingItemEntityProps } from './card-shopping-item.types';
export const CardShoppingItemEntityMobile = ({
  className,
  topRightSlot = null,
  bottomRight,
  bottomLeft,
  showPrice = false,
  variant = 'primary',
  disabled,
  size,
  name,
  age,
  itemImageSlot,
  itemPriceSlot,
  itemPropertiesSlot,
  ...props
}: CardShoppingItemEntityProps) => {
  const cardShoppingItemClasses = cn(
    styles['container'],
    { [styles['without-bottom-slots']]: !bottomRight && !bottomLeft },
    { [styles['disabled']]: disabled },
    { [styles[`size--${size}`]]: size },
    className,
  );
  const isClient = useIsClient();
  const isHydrated = useStore().app.isHydrated && isClient;

  return (
    <article className={cardShoppingItemClasses} {...props}>
      <div
        className={cn(styles['top-slot'], {
          [styles['with-bottom-slot']]: bottomRight || bottomLeft,
        })}
      >
        <div className={styles['top-slot__left']}>
          {itemImageSlot}

          {isHydrated ? (
            <div
              className={cn(styles['item-info'], {
                [styles['secondary']]: variant === 'secondary',
              })}
            >
              {variant === 'primary' && showPrice && (
                <>
                  {name && (
                    <Typography className={styles['title']}>{name}</Typography>
                  )}
                  {age && (
                    <Typography
                      style={{ textTransform: 'capitalize' }}
                      className={styles['age']}
                    >
                      {age}
                    </Typography>
                  )}
                  {itemPriceSlot}
                </>
              )}

              {variant === 'secondary' && (
                <>
                  {itemPriceSlot}
                  {name && (
                    <Typography
                      className={cn(styles['title'], styles['secondary'])}
                    >
                      {name}
                    </Typography>
                  )}
                </>
              )}
            </div>
          ) : (
            <Skeleton style={{ width: '100px', marginLeft: 20 }} />
          )}
        </div>
        <div className={styles['top-slot__right']}>
          {itemPropertiesSlot}

          {topRightSlot}
        </div>
      </div>
      {variant === 'primary' && (bottomLeft || bottomRight) && (
        <div className={styles['bottom-slot']}>
          {bottomLeft && (
            <div className={styles['bottom-slot__left']}>{bottomLeft}</div>
          )}
          {bottomRight && (
            <div className={styles['bottom-slot__right']}>{bottomRight}</div>
          )}
        </div>
      )}
    </article>
  );
};

CardShoppingItemEntityMobile.ItemImageSlot = ({
  product,
}: {
  product: ProductById;
}) => {
  const colorScheme =
    product.game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(styles['item'], {
        [styles['item-unavailable']]: status === 'unavailable',
      })}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={styles['background']}
        size={'53'}
        variant={product.rare}
      />
      <Item
        className={styles['item-variant']}
        size={'48'}
        src={product.imageUri}
      />
    </div>
  );
};

CardShoppingItemEntityMobile.ItemProperties = ({
  product,
}: {
  product: ProductById;
}) => {
  return (
    <div className={styles['properties']}>
      <TagRarity variant={product.rare} className={styles['rarity']} />
      {product.flyable && (
        <TagCategory variant={'fly'} className={styles['category']} />
      )}
      {product.rideable && (
        <TagCategory variant={'ride'} className={styles['category']} />
      )}
    </div>
  );
};

CardShoppingItemEntityMobile.ItemPrice = observer(
  ({
    price,
    status,
    variant = 'primary',
  }: { price: number; status: 'unavailable' | null } & {
    variant: CardShoppingItemEntityProps['variant'];
  }) => {
    const currencySign = useStore().app.getCurrencySign;

    if (status === 'unavailable') {
      return (
        <div className={styles['price']}>
          <Typography className={styles['status']}>Not available</Typography>
        </div>
      );
    }

    return (
      <div className={cn(styles['price'], styles[variant])}>
        <Typography className={cn(styles['current-price'], styles[variant])}>
          {currencySign}
          {price}
        </Typography>
        {Boolean(price) && (
          <Typography
            decoration={'line-through'}
            className={cn(styles['old-price'], styles[variant])}
          >
            {currencySign}
            {price}
          </Typography>
        )}
      </div>
    );
  },
);
