'use client';
import {
  CardInventoryEntityMobileDefaultProps,
  CardInventoryEntityMobileGiftProps,
  CardInventoryEntityMobileLimitedProps,
  CardInventoryItemInfoProps,
  CardInventoryItemPropertiesProps,
  CardInventoryItemProps,
  CardInventoryProps,
} from '@entities/mobile/cards/card-inventory/ui/card-inventory.types';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { WithCurrency } from '@/shared/hocs';
import {
  Item,
  ItemRarityCard,
  ProgressIndicator,
  TagCategory,
  TagRarity,
  Typography,
} from '@/shared/ui';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileCard,
  AdditionalInfoMobileDefaultIcon,
  AdditionalInfoMobileTimer,
} from '@/shared/ui/mobile/additional-info';

import styles from './card-inventory.module.scss';

export const CardInventoryEntityMobile = ({
  className,
  topMiddleSlot,
  topRightSlot,
  topLeftSlot,
  bottomLeftSlot,
  bottomRightSlot,
  disabled,
  ...props
}: CardInventoryProps) => {
  return (
    <motion.article
      className={cn(
        styles['card-inventory'],
        { [styles['disabled']]: disabled },
        className,
      )}
      {...props}>
      <div className={styles['top-slot']}>
        {topLeftSlot}
        {topMiddleSlot}
        <div className={styles['top-slot--right']}>{topRightSlot}</div>
      </div>
      <div id={'bottom-slot'} className={styles['bottom-slot']}>
        {bottomLeftSlot}
        {bottomRightSlot}
      </div>
    </motion.article>
  );
};

CardInventoryEntityMobile.Item = ({
  className,
  rare,
  game,
  src,
  ...props
}: CardInventoryItemProps) => {
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(
        styles['item-container'],
        styles['item-paddings'],
        className,
      )}
      {...props}>
      <ItemRarityCard
        colorScheme={colorScheme}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        size={'100%'}
        variant={rare}
      />
      <Item className={styles['item']} size={'48'} src={src} />
    </div>
  );
};

CardInventoryEntityMobile.ItemInfo = ({
  title,
  price,
  age,
  type,
}: CardInventoryItemInfoProps) => {
  return (
    <div className={styles['item-info-container']}>
      <div className={styles['info']}>
        {title && <Typography className={styles['title']}>{title}</Typography>}
        <Typography className={styles['subtitle']}>
          {formatAge(age ?? type)}
        </Typography>
        <div className={styles['price']}>
          <Typography className={styles['price--current']}>
            <WithCurrency>{price}</WithCurrency>
          </Typography>
          {price && (
            <Typography
              decoration={'line-through'}
              className={styles['price--old']}>
              <WithCurrency>{price}</WithCurrency>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

CardInventoryEntityMobile.ItemProperties = ({
  rare,
  flyable,
  rideable,
  ...props
}: CardInventoryItemPropertiesProps) => {
  return (
    <div className={styles['item-properties-container']} {...props}>
      {rare && <TagRarity variant={rare} className={styles['rarity']} />}

      {flyable && (
        <TagCategory className={styles['category']} variant={'fly'} />
      )}
      {rideable && (
        <TagCategory className={styles['category']} variant={'ride'} />
      )}
    </div>
  );
};

CardInventoryEntityMobile.SpendingProgress = ({
  price,
}: {
  price: { current: number; total: number };
}) => {
  return (
    <div className={styles['spending-progress']}>
      <div className={styles['price']}>
        <Typography className={styles['current']}>
          <WithCurrency>{price.current}</WithCurrency>
        </Typography>
        <Typography className={styles['total']}>/ {price.total}</Typography>
      </div>

      <ProgressIndicator
        staticWidth={(price.current / price.total) * 100}
        variant={'warn'}
      />

      <Typography className={styles['content']}>
        To purchase this item, you need to spend at least{' '}
        <WithCurrency>{price.total}</WithCurrency> on other items
      </Typography>
    </div>
  );
};

CardInventoryEntityMobile.Limited = ({
  flyable,
  rideable,
  rare,
  src,
  title,
  price,
  game,
  type,
  age,
  spendQuantityTotal,
  spendQuantityCurrent,
  claimWithin,
}: CardInventoryEntityMobileLimitedProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isTapped, setIsTapped] = useState<boolean>(false);

  return (
    <CardInventoryEntityMobile
      onTap={() => setIsTapped(prev => !prev)}
      topLeftSlot={
        isTapped ? (
          <CardInventoryEntityMobile.SpendingProgress
            price={{ total: spendQuantityTotal, current: spendQuantityCurrent }}
          />
        ) : (
          <CardInventoryEntityMobile.Item game={game} src={src} rare={rare} />
        )
      }
      topMiddleSlot={
        isTapped ? null : (
          <CardInventoryEntityMobile.ItemInfo
            title={title}
            price={price}
            age={age}
            type={type}
          />
        )
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            flyable={flyable}
            rideable={rideable}
            rare={rare}
          />
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobileTimer
          duration={claimWithin}
          status={'claim'}
          width={'full-w'}
          enableColorDiffByTime
        />
      }
      bottomLeftSlot={
        <AdditionalInfoMobileCard
          icon={'gift'}
          variant={'warn'}
          width={'full-w'}
          leftText={'Limited'}
          rightText={`${spendQuantityCurrent}/${spendQuantityTotal}$`}
        />
      }
    />
  );
};
CardInventoryEntityMobile.Gift = ({
  flyable,
  rideable,
  rare,
  src,
  title,
  price,
  claimWithin,
  checked,
  timesUp,
  handleTimesUp,
  toggleSelected,
  disabled,
  game,
  type,
  age,
  ...props
}: CardInventoryEntityMobileGiftProps) => {
  return (
    <CardInventoryEntityMobile
      disabled={disabled}
      onClick={props.onClick}
      topLeftSlot={
        <CardInventoryEntityMobile.Item game={game} src={src} rare={rare} />
      }
      topMiddleSlot={
        <CardInventoryEntityMobile.ItemInfo
          title={title}
          price={price}
          age={age}
          type={type}
        />
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            flyable={flyable}
            rideable={rideable}
            rare={rare}
          />
          {!timesUp && <Checkbox checked={checked} onChange={toggleSelected} />}
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobileTimer
          duration={claimWithin}
          enableColorDiffByTime
          status={'claim'}
          width={'full-w'}
          onTimesOut={handleTimesUp}
        />
      }
      bottomLeftSlot={
        <AdditionalInfoMobileDefaultIcon
          variant={'success'}
          withBackground
          name={'gift'}
        />
      }
    />
  );
};

CardInventoryEntityMobile.Default = ({
  flyable,
  rideable,
  rare,
  src,
  title,
  price,
  claimWithin,
  checked,
  type,
  age,
  timesUp,
  handleTimesUp,
  toggleSelected,
  disabled,
  game,
  ...props
}: CardInventoryEntityMobileDefaultProps) => {
  return (
    <CardInventoryEntityMobile
      onClick={props.onClick}
      topLeftSlot={
        <CardInventoryEntityMobile.Item game={game} src={src} rare={rare} />
      }
      topMiddleSlot={
        <CardInventoryEntityMobile.ItemInfo
          title={title}
          price={price}
          age={age}
          type={type}
        />
      }
      topRightSlot={
        <>
          <CardInventoryEntityMobile.ItemProperties
            flyable={flyable}
            rideable={rideable}
            rare={rare}
          />
          {!timesUp && <Checkbox checked={checked} onChange={toggleSelected} />}
        </>
      }
      bottomRightSlot={
        <AdditionalInfoMobileTimer
          duration={claimWithin}
          status={'claim'}
          width={'full-w'}
          onTimesOut={handleTimesUp}
        />
      }
    />
  );
};
